import { Box, keyframes, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/global/Types";

interface StyledNavbarProps extends StyledComponentProps {
  isextended: boolean;
}

export const StyledNavbar = styled(Box)(
  ({ theme, isextended }: StyledNavbarProps) => ({
    width: isextended ? "25rem" : "7.5rem",
    transition: ".5s",
    minHeight: "100%",
    background: theme.color.dark,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingBlock: "1rem",
    boxSizing: "border-box",
    ".header": {
      height: "6rem",
      color: theme.color.background,
      display: "flex",
      alignItems: "start",
      justifyContent: "space-between",

      padding: "1rem",
      img: {
        height: isextended ? "100%" : "75%",
        animation: `${logofade} 1500ms linear`,
      },
    },
    ".navlinks": {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
    },
    ".buttonWrapper": {
      display: "flex",
      paddingInline: ".5rem",
      gap: "1rem",
      ".actionBtn": {
        height: "4rem",
        width: "4rem",
        padding: "0",
        background: "none",
        border: `3px solid ${theme.color.main}`,
        "&:hover": {
          color: theme.color.main,
        },
      },
    },
  })
);

const logofade = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
