import { ReactNode } from "react";
import { StyledDarkOverlay } from "./DarkOverlay.styled";
import { X } from "@phosphor-icons/react";
import { Box } from "@mui/material";

type DarkOverlayProps = {
  opened: boolean;
  component: ReactNode;
  onClose?: () => void;
};

export const DarkOverlay = ({
  opened,
  component,
  onClose,
}: DarkOverlayProps) => {
  if (!opened) return <></>;

  return (
    <StyledDarkOverlay>
      <Box className="componentWrapper">{component}</Box>
      {onClose && (
        <X size={48} onClick={onClose} className="closeBtn" weight="bold" />
      )}
    </StyledDarkOverlay>
  );
};
