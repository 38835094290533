import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../../models/global/Types";
import { StyledCMS } from "../Cms.styled";

export const StyledAchievements = styled(StyledCMS)(
  ({ theme }: StyledComponentProps) => ({
    width: "100%",
    h1: {
      color: theme.color.dark,
    },
    ".achievementWrapper": {
      padding: "1rem",
      display: "flex",
      flexWrap: "wrap",
      gap: "1rem",
    },
  })
);
