import { styled } from "@mui/material";
import { StyledComponentProps } from "../../models/global/Types";

export const StyledForm = styled("form")(({ theme }: StyledComponentProps) => ({
  display: "flex",
  flexDirection: "column",
  gap: ".5rem",
  ".row": {
    display: "flex",
    gap: "1rem",
    width: "100%",
    ".column": {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      minWidth: "20rem",
      p: {
        color: theme.color.main,
      },
      ".imageWrapper": {
        width: "15rem",
        height: "15rem",
        border: `3px solid ${theme.color.main}`,
        borderRadius: "25px",
        overflow: "hidden",
        img: {
          width: "100%",
          height: "100%",
          objectFit: "cover",
        },
      },
      "input[type='file']": {
        cursor: "pointer",
        position: "absolute",
        width: "30rem",
        height: "3rem",
        opacity: "0",
      },
      ".fileInput": {
        position: "relative",
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        width: "100%",
        cursor: "pointer",
        height: "3rem",
        background: theme.contrast.lighter,
        borderRadius: "5px",
        paddingInline: "2rem",
        ".input-label": {
          color: theme.color.dark,
          fontWeight: "bold",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0",
        },
        ".icon-wrapper": {
          background: theme.palette.primary.main,
          height: "100%",
          width: "5rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      },
    },
  },
}));
