import { styled } from "@mui/material";
import { StyledComponentProps } from "../../../models/global/Types";

export const StyledMessageForm = styled("form")(
  ({ theme }: StyledComponentProps) => ({
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  })
);
