import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/global/Types";

interface StyledMessageComponentProps extends StyledComponentProps {
  align: string;
  background: string;
}

export const StyledMessageComponent = styled(Box)(
  ({ theme, align, background }: StyledMessageComponentProps) => ({
    alignSelf: align,
    boxShadow: `0px 0px 3px 3px ${theme.contrast.shadowColor}`,
    width: "fit-content",
    background: background,
    padding: ".5rem 1rem",
    borderRadius: "10px",
    minWidth: "20rem",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    gap: ".5rem",
    maxWidth: "45%",
    ".body": {
      fontWeight: "bold",
    },
  })
);
