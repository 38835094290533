import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/global/Types";

export const StyledChats = styled(Box)(({ theme }: StyledComponentProps) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingBlock: "2rem",
  boxSizing: "border-box",
  overflowY: "scroll",
  maxHeight: "100vh",
  ".chatWrapper": {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "1rem",
  },
  h3: {
    color: theme.color.main,
  },
}));
