import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/global/Types";

interface StyledImageGridItemProps extends StyledComponentProps {
  gridArea: string;
}

export const StyledImageGrid = styled(Box)(
  ({ theme }: StyledComponentProps) => ({
    display: "flex",
    flexDirection: "column",
    gap: ".5rem",
    zIndex: "100",
    width: "100%",
    padding: "0",
    margin: "0",
    ".grid": {
      display: "grid",
      gridTemplateColumns: "repeat(12, 1fr)",
      gridTemplateRows: "repeat(12, 1fr)",
      width: "100%",
      maxWidth: "40rem",
      boxSizing: "border-box",
      height: "20rem",
      margin: "auto",
      gap: ".5rem",
      borderRadius: "25px",
      overflow: "hidden",
      boxShadow: `0px 0px 3px 3px ${theme.contrast.shadowColor}`,
      border: `4px solid ${theme.color.main}`,
      background: theme.color.background,
    },
  })
);

export const StyledImageGridItem = styled(Box)(
  ({ theme, gridArea }: StyledImageGridItemProps) => ({
    gridArea: gridArea,
    position: "relative",
    zIndex: "2",
    button: {
      position: "absolute",
      display: "flex",
      gap: ".5rem",
      right: ".5rem",
      bottom: ".5rem",
      background: theme.color.main,
      color: theme.color.background,
    },
    img: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  })
);
