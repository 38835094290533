import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/global/Types";

export const StyledConfirmation = styled(Box)(
  ({ theme }: StyledComponentProps) => ({
    position: "absolute",
    right: "1rem",
    display: "flex",
    background: theme.color.background,
    height: "100%",
    gap: "1rem",
    alignItems: "center",
    borderRadius: "25px",
    button: {
      height: "3rem",
    },
    ".confirmBtn": {
      background: theme.palette.error.main,
    },
    ".declineBtn": {
      background: theme.palette.warning.main,
    },
  })
);
