import { useContext, useEffect, useState } from "react";
import { useService } from "../../../stores/useServices";
import { StyledServices } from "./Services.styled";
import { Service } from "../../../models/Service";
import { Box, Button, Typography } from "@mui/material";
import { ServiceComponent } from "../../../components/service/Service";
import { CmsNavbar } from "../../../components/cmsNavbar/CmsNavbar";
import { DarkOverlay } from "../../../components/darkOverlay/DarkOverlay";
import { ServicesForm } from "../../../components/form/ServicesForm";
import { LanguageContext } from "../../../globals/LanguageContext";

export const Services = () => {
  const { fetchServices } = useService();
  const [services, setServices] = useState<Service[] | undefined>();
  const [darkOverlayOpened, setDarkOverlayOpened] = useState<boolean>(false);
  const { dictionary } = useContext(LanguageContext);

  useEffect(() => {
    UpdateItems();
  }, []);

  const UpdateItems = () => {
    fetchServices().then((res) => {
      setServices(res);
    });
  };

  const onClose = () => {
    setDarkOverlayOpened(false);
    UpdateItems();
  };

  return (
    <StyledServices>
      <CmsNavbar />
      <DarkOverlay
        opened={darkOverlayOpened}
        component={<ServicesForm onSave={onClose} />}
        onClose={onClose}
      />
      <Typography variant="h1">
        {dictionary.pages.cms.tabs.services.title}
      </Typography>
      <Button onClick={() => setDarkOverlayOpened(true)}>
        {dictionary.buttons.newservicebtn}
      </Button>
      <Box className="serviceWrapper">
        {services?.map((service) => (
          <ServiceComponent service={service} onChange={UpdateItems} />
        ))}
      </Box>
    </StyledServices>
  );
};
