import { useCallback } from "react";

const useDateFormatter = () => {
  const formatDate = useCallback((dateString: string) => {
    const date = new Date(dateString);

    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
    const year = date.getUTCFullYear();

    const hours = date.getUTCHours().toString().padStart(2, "0");
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");

    const formattedDate = `${day}/${month}/${year} | ${hours}:${minutes}`;

    return formattedDate;
  }, []);
  return { formatDate };
};

export default useDateFormatter;
