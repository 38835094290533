import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../../models/global/Types";

export const StyledServiceContent = styled(Box)(
  ({ theme }: StyledComponentProps) => ({
    border: `3px solid ${theme.color.main}`,
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    minHeight: "30rem",
    borderRadius: "25px",
    paddingInline: "2rem",
    boxSizing: "border-box",
    alignItems: "center",
    gap: "2rem",
    position: "relative",
    ".buttonWrapper": {
      position: "absolute",
      right: "2rem",
      top: "1rem",
      display: "flex",
      gap: "1rem",
      zIndex: "59",
      svg: {
        cursor: "pointer",
        "&:hover": {
          color: theme.color.dark,
          transition: ".5s",
        },
      },
      ".editBtn": {
        color: theme.palette.warning.main,
      },
      ".removeBtn": {
        color: theme.palette.error.main,
      },
    },
    ".imageWrapper": {
      minWidth: "15rem",
      width: "15rem",
      height: "15rem",
      border: `3px solid ${theme.color.main}`,
      borderRadius: "25px",
      overflow: "hidden",
      img: {
        width: "100%",
        objectFit: "cover",
        height: "100%",
      },
    },
    ".body": {
      position: "relative",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      height: "20rem",
      gap: "1rem",
      paddingTop: "2rem",
      ".text": {
        maxHeight: "100%",
        paddingInline: ".5rem",
        overflowY: "scroll",
      },
    },
  })
);
