import { create } from "zustand";
import { Chat } from "../models/Chat";
import {
  createMessage,
  deleteChat,
  getChat,
  getChats,
} from "../repositories/ChatRepository";
import { Message } from "../models/Message";
import { Profile } from "../models/Profile";

export interface ChatStore {
  fetchChats: (user: Profile) => Promise<Chat[]>;
  fetchChat: (user: Profile, chatId: number) => Promise<Chat>;
  sendMessage: (
    user: Profile,
    chatId: string,
    message: Message
  ) => Promise<number | undefined>;
  removeChat: (user: Profile, chatId: number) => Promise<number | undefined>;
}

export const useChat = create<ChatStore>(() => ({
  fetchChats: (user) => {
    return getChats(user);
  },
  fetchChat: (user, chatId) => {
    return getChat(user, chatId);
  },
  sendMessage: (user, chatId, message) => {
    return createMessage(user, chatId, message);
  },
  removeChat: (user, chatId: number) => {
    return deleteChat(user, chatId);
  },
}));
