import axios, { AxiosResponse } from "axios";
import { SERVERURL } from "../globals/constants";
import { Language } from "../models/Language";
import { LanguageStore } from "../stores/useLanguage";
import { Set } from "../models/global/Set";
import { LanguageFormDTO } from "../models/dto/LanguageFormDTO";
import { Profile } from "../models/Profile";

export const getLanguages = async (user: Profile, set: Set<LanguageStore>) => {
  try {
    const response: AxiosResponse = await axios.get<Language[]>(
      `${SERVERURL}/language/getall`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    set((state) => ({ ...state, languageList: response.data }));
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};

export const createLanguage = async (
  user: Profile,
  language: LanguageFormDTO
): Promise<number> => {
  try {
    const response: AxiosResponse = await axios.post(
      `${SERVERURL}/language/create`,
      language,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return response.status;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};

export const updateLanguage = async (
  user: Profile,
  languageId: number,
  language: LanguageFormDTO
): Promise<number> => {
  try {
    const response: AxiosResponse = await axios.put(
      `${SERVERURL}/language/update/${languageId}`,
      language,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return response.status;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};
