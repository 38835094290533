import { Box, Typography } from "@mui/material";
import { Language } from "../../../models/Language";
import { StyledLanguageItem } from "./LanguageItem.styled";
import { PencilSimple, TrashSimple } from "@phosphor-icons/react";
import { Dispatch } from "react";

type LanguageItemProps = {
  languageitem: Language;
  onEdit: (language: Language) => void;
};

export const LanguageItem = ({ languageitem, onEdit }: LanguageItemProps) => {
  return (
    <StyledLanguageItem>
      <Typography variant="h3" className="title">
        {languageitem.name}
      </Typography>
      <Typography variant="body1" className="value">
        {languageitem.translationDutch} / {languageitem.translationEnglish}
      </Typography>
      <Box className="buttonWrapper">
        <PencilSimple
          size={32}
          className="editBtn"
          weight="bold"
          onClick={() => {
            onEdit(languageitem);
          }}
        />
      </Box>
    </StyledLanguageItem>
  );
};
