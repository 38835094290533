import axios, { AxiosResponse } from "axios";
import { SERVERURL } from "../globals/constants";
import { Chat } from "../models/Chat";
import { Message } from "../models/Message";
import { Profile } from "../models/Profile";

export const getChats = async (user: Profile) => {
  try {
    const response: AxiosResponse = await axios.get<Chat[]>(
      `${SERVERURL}/chat/getall`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};

export const getChat = async (user: Profile, chatId: number) => {
  try {
    const response: AxiosResponse = await axios.get<Chat>(
      `${SERVERURL}/chat/get/${chatId}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};

export const createMessage = async (
  user: Profile,
  chatId: string,
  message: Message
) => {
  try {
    const response: AxiosResponse = await axios.post(
      `${SERVERURL}/chat/createMessage/${chatId}`,
      message,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return response.status;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};

export const deleteChat = async (user: Profile, chatId: number) => {
  try {
    const response: AxiosResponse = await axios.delete(
      `${SERVERURL}/chat/remove/${chatId}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return response.status;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};
