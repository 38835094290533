import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { isInDevMode } from "../globals/constants";

export const Redirect = () => {
  const [location, setLocation] = useState<string>("");
  const { path } = useParams();

  useMemo(() => {
    switch (path) {
      case "logout":
        setLocation(
          isInDevMode ? "http://localhost:3000" : "https://www.selico.be"
        );
        break;
    }
  }, []);

  useEffect(() => {
    window.location.href = location;
  }, [path]);

  return <>Redirecting</>;
};
