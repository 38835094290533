import {
  CaretLeft,
  CaretRight,
  Gear,
  Power,
  ShoppingCartSimple,
} from "@phosphor-icons/react";
import { ActionButton } from "../models/global/Types";

const iconSizeActionButton = 40;
export const isInDevMode = false;
export const SERVERURL = isInDevMode
  ? "http://localhost:5000/api"
  : "https://backend.selico.be/api";
export const defaultEmail = "info@selico.be";

export const actionButtons: ActionButton[] = [
  {
    icon: <CaretLeft size={iconSizeActionButton} weight="bold" />,
    altIcon: <CaretRight size={iconSizeActionButton} weight="bold" />,
    path: "/",
  },
  {
    icon: <Gear size={iconSizeActionButton} weight="bold" />,
    path: "/settings",
  },
  {
    icon: <ShoppingCartSimple size={iconSizeActionButton} weight="bold" />,
    path: "/shopping",
  },
  {
    icon: <Power size={iconSizeActionButton} weight="bold" />,
    path: "/redirect/logout",
  },
];
