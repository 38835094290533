import { useForm } from "react-hook-form";
import { StyledLoginForm } from "./LoginForm.styled";
import { LoginDto } from "../../models/dto/LoginDTO";
import { useProfile } from "../../stores/useProfile";
import { Button, TextField, Typography } from "@mui/material";
import { useContext } from "react";
import { LanguageContext } from "../../globals/LanguageContext";

export const LoginForm = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginDto>({});

  const { fetchProfile } = useProfile();
  const { dictionary } = useContext(LanguageContext);

  const onSubmit = async (data: LoginDto) => {
    await fetchProfile(data);
  };

  return (
    <StyledLoginForm onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h4">{dictionary.loginform.input_email}</Typography>
      <TextField
        {...register("username", {
          required: dictionary.errormessages.form_fieldrequired,
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: dictionary.errormessages.form_wrongemail,
          },
        })}
        error={!!errors.username}
        helperText={errors?.username?.message}
        InputLabelProps={{ shrink: true }}
        fullWidth
      />
      <Typography variant="h4">
        {dictionary.loginform.input_password}
      </Typography>
      <TextField
        type="password"
        {...register("password", {
          required: dictionary.errormessages.form_fieldrequired,
        })}
        error={!!errors.password}
        helperText={errors?.password?.message}
        InputLabelProps={{ shrink: true }}
      />
      <Button type="submit" variant="outlined">
        {dictionary.buttons.loginbtn}
      </Button>
    </StyledLoginForm>
  );
};
