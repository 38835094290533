import { create } from "zustand";
import { Profile } from "../models/Profile";
import { AchievementDto } from "../models/dto/AchievementDTO";
import { Achievement } from "../models/Achievement";
import {
  createAchievement,
  deleteAchievement,
  getAllAchievements,
} from "../repositories/AchievementRepository";

export interface AchievementStore {
  addAchievement: (
    user: Profile,
    achievement: AchievementDto
  ) => Promise<number | undefined>;
  removeAchievement: (
    user: Profile,
    achievementId: number
  ) => Promise<number | undefined>;
  fetchAchievements: () => Promise<Achievement[] | undefined>;
}

export const useAchievement = create<AchievementStore>(() => ({
  addAchievement: (user, achievement) => {
    return createAchievement(user, achievement);
  },
  fetchAchievements: () => {
    return getAllAchievements();
  },
  removeAchievement: (user, achievementId) => {
    return deleteAchievement(user, achievementId);
  },
}));
