import { Controller, useForm } from "react-hook-form";
import { AchievementDto } from "../../models/dto/AchievementDTO";
import { Box, Button, Input, TextField, Typography } from "@mui/material";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../globals/LanguageContext";
import { Upload } from "@phosphor-icons/react";
import { StyledForm } from "./Form.styled";
import { ImageGrid } from "../imageGrid/ImageGrid";
import { log } from "console";
import { useProfile } from "../../stores/useProfile";
import { useAchievement } from "../../stores/useAchievement";

type AchievementFormProps = {
  onSave: () => void;
};

export const AchievementForm = ({ onSave }: AchievementFormProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AchievementDto>({});
  const { dictionary } = useContext(LanguageContext);
  const [fileCount, setFileCount] = useState<number>(0);
  const [previewFiles, setPreviewFiles] = useState<string[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const { user } = useProfile();
  const { addAchievement } = useAchievement();

  const onSubmit = (data: AchievementDto) => {
    const newData = {
      ...data,
      files: selectedFiles,
    };

    if (!user) return;
    addAchievement(user, newData).then((res) => {
      onSave();
    });
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    setPreviewFiles([]);
    setSelectedFiles([]);
    if (fileList) {
      const files = Array.from(fileList);
      setFileCount(files.length);

      const newFiles: string[] = [];

      files.forEach((file) => {
        if (file) {
          setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, file]);
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64String = reader.result as string;
            newFiles.push(base64String.split(",")[1]);

            if (newFiles.length === files.length) {
              setPreviewFiles((prevSelectedFiles) => [
                ...prevSelectedFiles,
                ...newFiles,
              ]);
            }
          };
          reader.readAsDataURL(file);
        }
      });
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Box className="row">
        <Box className="column">
          <Typography variant="h3">
            {dictionary.achievementform.location}
          </Typography>
          <Controller
            name="location"
            rules={{ required: dictionary.errormessages.form_fieldrequired }}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Box>
      </Box>
      <Box className="row">
        <Box className="column">
          <Typography variant="h3">
            {dictionary.achievementform.descriptiondutch}
          </Typography>
          <Controller
            name="descriptionDutch"
            rules={{ required: dictionary.errormessages.form_fieldrequired }}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Box>
        <Box className="column">
          <Typography variant="h3">
            {dictionary.achievementform.descriptionenglish}
          </Typography>
          <Controller
            name="descriptionEnglish"
            rules={{ required: dictionary.errormessages.form_fieldrequired }}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Box>
      </Box>
      <Box className="row">
        <Box className="column">
          <Typography variant="h3">
            {dictionary.pages.cms.tabs.achievements.form.images}
          </Typography>
          <Box className="fileInput">
            <Input
              type="file"
              inputProps={{ multiple: true }}
              onChange={handleFileChange}
            />
            <Typography className="input-label">
              {dictionary.pages.cms.tabs.achievements.form.chooseimage}
              <Upload size={32} />
            </Typography>
          </Box>
          <Typography variant="body1">
            {fileCount +
              dictionary.pages.cms.tabs.achievements.form.filesselected}
          </Typography>
        </Box>
        <Box className="column"></Box>
      </Box>
      <Box className="row">
        <ImageGrid imageList={previewFiles} />
      </Box>

      <Button type="submit">{dictionary.buttons.savebtn}</Button>
    </StyledForm>
  );
};
