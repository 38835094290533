import { create } from "zustand";
import {
  createService,
  getService,
  getAllServices,
  createServiceContent,
  deleteServiceContent,
  deleteService,
  updateService,
} from "../repositories/ServiceRepository";
import { Service } from "../models/Service";
import { ServiceContentFormDTO } from "../models/dto/ServiceContentFormDTO";
import { ServiceFormDTO } from "../models/dto/ServiceFormDTO";
import { Profile } from "../models/Profile";

export interface ServiceStore {
  addService: (
    user: Profile,
    service: ServiceFormDTO
  ) => Promise<number | undefined>;
  editService: (
    user: Profile,
    serviceId: number,
    service: ServiceFormDTO
  ) => Promise<number | undefined>;
  addServiceContent: (
    user: Profile,
    serviceContent: ServiceContentFormDTO,
    serviceId: number
  ) => Promise<number | undefined>;
  removeServiceContent: (
    user: Profile,
    contentId: number
  ) => Promise<number | undefined>;
  removeService: (
    user: Profile,
    serviceId: number
  ) => Promise<number | undefined>;
  fetchServices: () => Promise<Service[] | undefined>;
  fetchService: (id: number) => Promise<Service | undefined>;
}

export const useService = create<ServiceStore>(() => ({
  addService: (user, service) => {
    return createService(user, service);
  },
  fetchServices: () => {
    return getAllServices();
  },
  fetchService: (id) => {
    return getService(id);
  },
  editService: (user, serviceId, service) => {
    return updateService(user, serviceId, service);
  },
  addServiceContent: (user, serviceContent, serviceId) => {
    return createServiceContent(user, serviceContent, serviceId);
  },
  removeServiceContent: (user, contentId) => {
    return deleteServiceContent(user, contentId);
  },
  removeService: (user, serviceId) => {
    return deleteService(user, serviceId);
  },
}));
