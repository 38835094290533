import {
  Box,
  Button,
  Input,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { StyledForm } from "./Form.styled";
import { Controller, useForm } from "react-hook-form";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useLanguage } from "../../stores/useLanguage";
import { useService } from "../../stores/useServices";
import { ServiceFormDTO } from "../../models/dto/ServiceFormDTO";
import { Service } from "../../models/Service";
import { LanguageContext } from "../../globals/LanguageContext";
import { Upload } from "@phosphor-icons/react";
import { useProfile } from "../../stores/useProfile";

type ServiceForm = {
  onSave: () => void;
  service?: Service;
};

export const ServicesForm = ({ onSave, service }: ServiceForm) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<ServiceFormDTO>({ defaultValues: { enabled: true } });
  const { dictionary } = useContext(LanguageContext);
  const { addService, editService } = useService();
  const { languageList, fetchLanguages } = useLanguage();
  const [imagePreview, setImagePreview] = useState<string | undefined>(
    service?.image
  );

  const { user } = useProfile();

  useEffect(() => {
    if (!user) return;
    fetchLanguages(user);
  }, []);

  const onSubmit = async (data: ServiceFormDTO) => {
    if (!user) return;
    const res = service
      ? await editService(user, service.id, data)
      : await addService(user, data);
    if (res) onSave();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        setImagePreview(base64String.split(",")[1]);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview("");
    }
  };

  useEffect(() => {
    setImagePreview(service?.image);
  }, [service?.image]);

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Box className="row">
        <Box className="column">
          <Typography variant="h3">
            {dictionary.pages.cms.tabs.services.form.title}
          </Typography>
          <Controller
            rules={{ required: true }}
            control={control}
            name="titleId"
            defaultValue={service?.title.id}
            render={({ field }) => (
              <Select {...field}>
                {languageList?.map((language) => (
                  <MenuItem value={language.id}>{language.name}</MenuItem>
                ))}
              </Select>
            )}
          />
        </Box>
        <Box className="column">
          <Typography variant="h3">
            {dictionary.pages.cms.tabs.services.form.body}
          </Typography>
          <Controller
            rules={{ required: true }}
            control={control}
            name="descriptionId"
            defaultValue={service?.description.id}
            render={({ field }) => (
              <Select {...field}>
                {languageList?.map((language) => (
                  <MenuItem value={language.id}>{language.name}</MenuItem>
                ))}
              </Select>
            )}
          />
        </Box>
      </Box>
      <Box className="row">
        <Box className="column">
          <Typography variant="h3">
            {dictionary.pages.cms.tabs.services.form.image}
          </Typography>
          <Box className="fileInput">
            <Input
              type="file"
              {...register("files")}
              inputProps={{ multiple: false }}
              onChange={handleFileChange}
            />
            <Typography className="input-label">
              {dictionary.pages.cms.tabs.services.form.chooseimage}
              <Upload size={32} />
            </Typography>
          </Box>
          <Box className="imageWrapper">
            <img src={`data:image/png;base64,${imagePreview}`} />
          </Box>
        </Box>
        <Box className="column">
          <Typography variant="h3">
            {dictionary.pages.cms.tabs.services.form.enabled}
          </Typography>
          <Controller
            rules={{ required: true }}
            control={control}
            name="enabled"
            defaultValue={service?.enabled}
            render={({ field }) => (
              <ToggleButtonGroup
                {...field}
                className="radioGroup"
                onChange={(
                  event: React.MouseEvent<HTMLElement>,
                  value: boolean
                ) => {
                  setValue(field.name, value);
                }}
                exclusive
              >
                <ToggleButton value={true}>
                  {dictionary.pages.cms.tabs.services.form.enabledtrue}
                </ToggleButton>
                <ToggleButton value={false}>
                  {dictionary.pages.cms.tabs.services.form.enabledfalse}
                </ToggleButton>
              </ToggleButtonGroup>
            )}
          />
        </Box>
      </Box>

      <Button type="submit">Opslaan</Button>
    </StyledForm>
  );
};
