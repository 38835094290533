import { Button } from "@mui/material";
import { TextArea } from "../../textArea/TextArea";
import { StyledMessageForm } from "./MessageForm.styled";
import { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../../globals/LanguageContext";
import { useForm } from "react-hook-form";
import { Message } from "../../../models/Message";
import { defaultEmail } from "../../../globals/constants";
import { useChat } from "../../../stores/useChat";
import { useProfile } from "../../../stores/useProfile";

type MessageFormProps = {
  chatId: string;
  onSave: () => void;
};

export const MessageForm = ({ chatId, onSave }: MessageFormProps) => {
  const { dictionary } = useContext(LanguageContext);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<Message>();

  const [content, setContent] = useState<string>("");

  const { sendMessage } = useChat();
  const { user } = useProfile();

  const onSubmit = async () => {
    const newMessage: Message = {
      content: content,
      timeStamp: new Date(),
      sender: defaultEmail,
    };
    if (!user) return;
    await sendMessage(user, chatId, newMessage);
    onSave();
    setContent("");
  };

  return (
    <StyledMessageForm onSubmit={handleSubmit(onSubmit)}>
      <TextArea
        setValue1={setContent}
        fullWidth
        multiline
        InputLabelProps={{ shrink: true }}
        InputProps={{
          rows: 5,
        }}
        value={content}
      />
      <Button type="submit">{dictionary.buttons.sendbtn}</Button>
    </StyledMessageForm>
  );
};
