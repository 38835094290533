import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/global/Types";

export const StyledTextArea = styled(Box)(
  ({ theme }: StyledComponentProps) => ({
    display: "flex",
    gap: "1rem",

    ".inputWrapper": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      ".buttonWrapper": {
        width: "100%",
        display: "flex",
        gap: ".5rem",
        background: theme.contrast.lighter,
        borderBottom: `2px solid ${theme.color.dark}`,
        padding: ".5rem",
        boxSizing: "border-box",
        borderRadius: "5px 5px 0 0",
        button: {
          background: "none",
          color: theme.color.main,
          textTransform: "inherit",
          minWidth: "0",
          minHeight: "0",
          borderRadius: "5px",
          boxShadow: `0px 0px 3px 3px ${theme.contrast.shadowColor}`,
          padding: ".25rem",
        },
        ".color": {
          width: "24px",
          height: "24px",
          borderRadius: "5px",
        },
        ".color1": {
          background: theme.color.dark,
        },
        ".color2": {
          background: theme.color.main,
        },
        ".color3": {
          background: theme.color.background,
        },
        ".color4": {
          background: theme.color.lighter,
        },
      },
    },
    ".exampleWrapper": {
      width: "30rem",
      maxHeight: "18.5rem",
      overflowY: "scroll",
      h3: {
        fontWeight: "bold",
        color: theme.color.main,
      },
      h4: {
        a: {
          textDecoration: "none",
        },
      },
    },
  })
);
