import { Box, Typography, Collapse, useTheme } from "@mui/material";
import { CaretDown, CaretLeft, Translate } from "@phosphor-icons/react";
import { LanguageGroup } from "../../../models/LanguageGroup";
import { StyledLanguageGroup } from "./LanguageGroup.styled";
import { Dispatch, useState } from "react";
import { LanguageItem } from "../languageItem/LanguageItem";
import { Language } from "../../../models/Language";

type LanguageGroupProps = {
  languageGroup: LanguageGroup;
  onEdit: (language: Language) => void;
};

export const LanguageGroupComponent = ({
  languageGroup,
  onEdit,
}: LanguageGroupProps) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const theme = useTheme();

  return (
    <StyledLanguageGroup theme={theme} isopened={isOpened}>
      <Box className="header" onClick={() => setIsOpened(!isOpened)}>
        <Translate size={50} />
        <Typography variant="h2">{languageGroup.group}</Typography>
        {isOpened ? (
          <CaretDown size={40} weight="bold" />
        ) : (
          <CaretLeft size={40} weight="bold" />
        )}
      </Box>
      <Collapse in={isOpened}>
        <Box className={`body ${isOpened && "opened"}`}>
          {languageGroup.items.map((languageItem) => (
            <LanguageItem languageitem={languageItem} onEdit={onEdit} />
          ))}
        </Box>
      </Collapse>
    </StyledLanguageGroup>
  );
};
