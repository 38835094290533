import {
  Box,
  Button,
  TextField,
  TextFieldProps,
  Typography,
  useTheme,
} from "@mui/material";
import { StyledTextArea } from "./TextArea.styled";
import {
  useContext,
  useRef,
  useState,
  useEffect,
  useCallback,
  Dispatch,
} from "react";
import { useTextFormatter } from "../../hooks/useTextFormatter";
import { LanguageContext } from "../../globals/LanguageContext";
import {
  KeyReturn,
  Link,
  TextB,
  TextHOne,
  TextHTwo,
} from "@phosphor-icons/react";

export type TextAreaProps = Omit<TextFieldProps, "variant"> & {
  setValue1?: Dispatch<React.SetStateAction<string>>;
  setValue2?: Dispatch<React.SetStateAction<string>>;
  value?: string;
  fullWidth?: boolean;
};

export const TextArea = ({
  setValue1,
  setValue2,
  value,
  fullWidth,
  ...props
}: TextAreaProps) => {
  const [text, setText] = useState(value || "");
  const [history, setHistory] = useState<string[]>([]);
  const textFieldRef = useRef(null);
  const { renderText } = useTextFormatter();
  const { dictionary } = useContext(LanguageContext);
  const theme = useTheme();

  const replaceSelectedText = (markup: {
    openingTag: string;
    closingTag: string;
  }) => {
    const input = textFieldRef.current;
    if (input) {
      const { selectionStart, selectionEnd } = input;
      const selectedText = text.substring(selectionStart, selectionEnd);
      const newText = `${markup.openingTag}${selectedText}${markup.closingTag}`;

      setText(
        text.slice(0, selectionStart) + newText + text.slice(selectionEnd)
      );
    }
  };

  const handleBoldClick = () => {
    replaceSelectedText({ openingTag: "[bold]", closingTag: "[/bold]" });
  };

  const handleTextSizeClick = (textSize: string) => {
    replaceSelectedText({
      openingTag: `[${textSize}]`,
      closingTag: `[/${textSize}]`,
    });
  };

  const handleLinkClick = () => {
    replaceSelectedText({
      openingTag: `[link:"/path"]`,
      closingTag: "[/link]",
    });
  };

  const handleBreaklineClick = () => {
    replaceSelectedText({
      openingTag: `[/breakline]`,
      closingTag: "",
    });
  };

  const handleColorClick = (color: string) => {
    replaceSelectedText({
      openingTag: `[color:"${color}"]`,
      closingTag: "[/color]",
    });
  };

  useEffect(() => {
    if (value !== undefined) {
      setText(value);
    }
  }, [value]);

  useEffect(() => {
    setHistory((prevHistory) => [...prevHistory, text]);
    setValue1 && setValue1(text);
    setValue2 && setValue2(text);
  }, [text]);

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.ctrlKey && event.key === "z") {
      event.preventDefault();
      setHistory((prevHistory) => {
        if (prevHistory.length > 1) {
          const newHistory = [...prevHistory];
          newHistory.pop();
          setText(newHistory[newHistory.length - 1]);
          return newHistory;
        }
        return prevHistory;
      });
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <StyledTextArea>
      <Box className="inputWrapper">
        <Box className="buttonWrapper">
          <Button onClick={handleBoldClick}>
            <TextB size={24} weight="bold" />
          </Button>
          <Button onClick={() => handleTextSizeClick("h1")}>
            <TextHOne size={24} weight="bold" />
          </Button>
          <Button onClick={() => handleTextSizeClick("h2")}>
            <TextHTwo size={24} weight="bold" />
          </Button>
          <Button onClick={handleLinkClick}>
            <Link size={24} weight="bold" />
          </Button>
          <Button onClick={handleBreaklineClick}>
            <KeyReturn size={24} weight="bold" />
          </Button>
          <Button onClick={() => handleColorClick(theme.color.dark)}>
            <Box className="color color1" />
          </Button>
          <Button onClick={() => handleColorClick(theme.color.main)}>
            <Box className="color color2" />
          </Button>
          <Button onClick={() => handleColorClick(theme.color.background)}>
            <Box className="color color3" />
          </Button>
          <Button onClick={() => handleColorClick(theme.color.lighter)}>
            <Box className="color color4" />
          </Button>
        </Box>
        <TextField
          {...props}
          inputRef={textFieldRef}
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </Box>
      {!fullWidth && (
        <Box className="exampleWrapper">
          <Typography variant="h3">
            {dictionary.pages.cms.tabs.language.example}
          </Typography>
          <Typography variant="h4">{renderText(text)}</Typography>
        </Box>
      )}
    </StyledTextArea>
  );
};
