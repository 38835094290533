import { useContext, useEffect, useMemo, useState } from "react";
import { useLanguage } from "../../../stores/useLanguage";
import { StyledLanguages } from "./Language.styled";
import { CmsNavbar } from "../../../components/cmsNavbar/CmsNavbar";
import { Box, Button, Typography } from "@mui/material";
import { DarkOverlay } from "../../../components/darkOverlay/DarkOverlay";
import { LanguageGroup } from "../../../models/LanguageGroup";
import { LanguageGroupComponent } from "../../../components/language/languageGroup/LanguageGroup";
import { LanguageContext } from "../../../globals/LanguageContext";
import { LanguageForm } from "../../../components/language/languageForm/LanguageForm";
import { Language } from "../../../models/Language";
import { useProfile } from "../../../stores/useProfile";

export const Languages = () => {
  const { fetchLanguages, languageList } = useLanguage();
  const { dictionary } = useContext(LanguageContext);
  const [darkOverlayOpened, setDarkOverlayOpened] = useState<boolean>(false);
  const [sortedLanguages, setSortedLanguages] = useState<LanguageGroup[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<
    Language | undefined
  >(undefined);

  const { user } = useProfile();

  const updateItems = async () => {
    if (!user) return;
    const res = await fetchLanguages(user);
    if (res) {
      const languageArray = res.reduce((result: any, item) => {
        const group = item.group;
        const existingGroup = result.find((g: any) => g.group === group);
        if (existingGroup) {
          existingGroup.items.push(item);
        } else {
          result.push({
            group: group,
            items: [item],
          });
        }
        return result;
      }, []);
      setSortedLanguages([...languageArray]);
    }
  };

  useEffect(() => {
    updateItems();
  }, []);

  const onClose = () => {
    setDarkOverlayOpened(false);
    setSelectedLanguage(undefined);
    updateItems();
  };

  const onEdit = (language: Language) => {
    setSelectedLanguage(language);
    setDarkOverlayOpened(true);
  };

  return (
    <StyledLanguages>
      <CmsNavbar />
      <DarkOverlay
        opened={darkOverlayOpened}
        component={
          <LanguageForm onSave={onClose} language={selectedLanguage} />
        }
        onClose={onClose}
      />
      <Typography variant="h1">
        {dictionary.pages.cms.tabs.language.title}
      </Typography>
      <Button onClick={() => setDarkOverlayOpened(true)}>
        {dictionary.buttons.newlanguagebtn}
      </Button>
      <Box className="languageWrapper">
        {sortedLanguages &&
          sortedLanguages.map((languageGroup) => (
            <LanguageGroupComponent
              key={languageGroup.group}
              languageGroup={languageGroup}
              onEdit={onEdit}
            />
          ))}
      </Box>
    </StyledLanguages>
  );
};
