import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../../models/global/Types";

export const StyledLanguageItem = styled(Box)(
  ({ theme }: StyledComponentProps) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    ".title": {
      color: theme.color.dark,
      textTransform: "capitalize",
      fontWeight: "bold",
      width: "35%",
    },
    ".value": {
      color: theme.color.dark,
      textTransform: "capitalize",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      height: "2rem",
      display: "flex",
      alignItems: "center",
      width: "50%",
      fontWeight: "bold",
      b: {
        color: theme.color.main,
      },
    },
    ".buttonWrapper": {
      display: "flex",
      gap: "1rem",
      svg: {
        cursor: "pointer",
        "&:hover": {
          color: theme.color.dark,
          transition: ".5s",
        },
      },
      ".editBtn": {
        color: theme.palette.warning.main,
      },
      ".removeBtn": {
        color: theme.palette.error.main,
      },
    },
  })
);
