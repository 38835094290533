import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../../models/global/Types";

export const StyledLanguageForm = styled("form")(
  ({ theme }: StyledComponentProps) => ({
    display: "flex",
    flexDirection: "column",
    gap: ".5rem",
    ".row": {
      display: "flex",
      gap: "1rem",
      ".column": {
        display: "flex",
        flexDirection: "column",
      },
    },
  })
);
