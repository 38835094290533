import { Box, Typography } from "@mui/material";
import { StyledChats } from "./Chats.styled";
import { ChatComponent } from "../../components/chatComponent/ChatComponent";
import { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../globals/LanguageContext";
import { useChat } from "../../stores/useChat";
import { Chat } from "../../models/Chat";
import { useProfile } from "../../stores/useProfile";

export const Chats = () => {
  const { dictionary } = useContext(LanguageContext);
  const { fetchChats } = useChat();
  const [chats, setChats] = useState<Chat[]>([]);

  const { user } = useProfile();

  useEffect(() => {
    updateItems();
  }, []);

  const updateItems = () => {
    if (!user) return;
    fetchChats(user).then((res) => {
      setChats(res);
    });
  };

  return (
    <StyledChats>
      <Typography variant="h1">{dictionary.pages.chats.title}</Typography>
      <Box className="chatWrapper">
        {chats.map((chat, index) => (
          <ChatComponent chat={chat} key={index} />
        ))}
      </Box>
      {chats.length === 0 && (
        <Typography variant="h3">
          {dictionary.errormessages.chats_nochats}
        </Typography>
      )}
    </StyledChats>
  );
};
