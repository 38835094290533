import { Box, Typography } from "@mui/material";
import { Achievement } from "../../models/Achievement";
import { StyledAchievementComponent } from "./AchievementComponent.styled";
import { PencilSimple, TrashSimple } from "@phosphor-icons/react";
import { useState } from "react";
import { Confirmation } from "../confirmation/Confirmation";
import { useAchievement } from "../../stores/useAchievement";
import { useProfile } from "../../stores/useProfile";

type AchievementComponentProps = {
  achievement: Achievement;
  onUpdate: () => void;
};

export const AchievementComponent = ({
  achievement,
  onUpdate,
}: AchievementComponentProps) => {
  const { removeAchievement } = useAchievement();
  const { user } = useProfile();

  const onDelete = async () => {
    if (!user) return;
    await removeAchievement(user, achievement.id);
    onUpdate();
  };

  return (
    <StyledAchievementComponent>
      <TrashSimple size={40} weight="bold" onClick={onDelete} />
      <Box className="header">
        <img src={`data:image/png;base64,${achievement.images[0]}`} />
      </Box>
      <Box className="body">
        <Typography variant="h3">{achievement.location}</Typography>
        <Typography variant="h4">
          {achievement.description.translationDutch} |{" "}
          {achievement.description.translationEnglish}
        </Typography>
      </Box>
    </StyledAchievementComponent>
  );
};
