import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { Navbar } from "./components/navbar/Navbar";
import { Home } from "./pages/home/Home";
import { theme } from "./globals/theme";
import { Redirect } from "./components/Redirect";
import { Services } from "./pages/cms/services/Services";
import { ServicePage } from "./pages/cms/services/service/Service";
import { Languages } from "./pages/cms/language/Language";
import { useEffect, useState } from "react";
import { dictionaryList, LanguageContext } from "./globals/LanguageContext";
import { Chats } from "./pages/chats/Chats";
import { ChatPage } from "./pages/chats/chatPage/ChatPage";
import { useProfile } from "./stores/useProfile";
import { DarkOverlay } from "./components/darkOverlay/DarkOverlay";
import { LoginForm } from "./components/loginForm/LoginForm";
import { Achievements } from "./pages/cms/achievements/Achievement";
export const App = () => {
  //eslint-disable-next-line

  const [language, setLanguage] = useState("nl_BE");
  const [darkOverlayOpened, setDarkOverlayOpened] = useState<boolean>(false);
  const { user } = useProfile();

  useEffect(() => {
    !user && setDarkOverlayOpened(true);
  }, [user]);

  return (
    <>
      <LanguageContext.Provider
        value={{ language, dictionary: dictionaryList[language] }}
      >
        <ThemeProvider theme={theme}>
          <Router>
            {!user && (
              <DarkOverlay
                opened={darkOverlayOpened}
                component={<LoginForm />}
              />
            )}
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/redirect/:path" element={<Redirect />} />
              <Route path="/chats" element={<Chats />} />
              <Route path="/chats/:id" element={<ChatPage />} />
              <Route path="/cms/services" element={<Services />} />
              <Route path="/cms/language" element={<Languages />} />
              <Route path="/cms/services/:id" element={<ServicePage />} />
              <Route path="/cms/achievements" element={<Achievements />} />

              <Route path="*" element={<></>} />
            </Routes>
          </Router>
        </ThemeProvider>
      </LanguageContext.Provider>
    </>
  );
};
