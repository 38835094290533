import { Typography, useTheme } from "@mui/material";
import { useTextFormatter } from "../../hooks/useTextFormatter";
import { Message } from "../../models/Message";
import { StyledMessageComponent } from "./Message.styled";
import useDateFormatter from "../../hooks/useDateFormatter";
import { defaultEmail } from "../../globals/constants";

type MessageComponentProps = {
  message: Message;
};

export const MessageComponent = ({ message }: MessageComponentProps) => {
  const { renderText } = useTextFormatter();
  const { formatDate } = useDateFormatter();
  const theme = useTheme();
  return (
    <StyledMessageComponent
      theme={theme}
      align={message.sender === defaultEmail ? "end" : "start"}
      background={
        message.sender === defaultEmail
          ? theme.color.main
          : theme.color.background
      }
      color={
        message.sender === defaultEmail
          ? theme.color.background
          : theme.color.dark
      }
    >
      <Typography
        variant="body1"
        color={
          message.sender === defaultEmail
            ? theme.color.background
            : theme.contrast.main
        }
      >
        {message.sender} {formatDate(message.timeStamp.toString())}
      </Typography>
      <Typography variant="body1" className="body">
        {renderText(message.content)}
      </Typography>
    </StyledMessageComponent>
  );
};
