import axios, { AxiosResponse } from "axios";
import { SERVERURL } from "../globals/constants";
import { Profile } from "../models/Profile";
import { Achievement } from "../models/Achievement";
import { AchievementDto } from "../models/dto/AchievementDTO";

export const getAllAchievements = async () => {
  const { data } = await axios.get<Achievement[]>(
    `${SERVERURL}/achievement/getall`,
    {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    }
  );

  return data;
};

export const createAchievement = async (
  user: Profile,
  achievement: AchievementDto
): Promise<number> => {
  const formData = new FormData();
  if (achievement.files) {
    const fileList = achievement.files;

    fileList.forEach((file) => {
      formData.append("files", file);
    });
  }

  formData.append("data", JSON.stringify(achievement));

  try {
    const response: AxiosResponse = await axios.post(
      `${SERVERURL}/achievement/create`,
      formData,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return response.status;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};

export const deleteAchievement = async (
  user: Profile,
  achievementId: number
) => {
  try {
    const response: AxiosResponse = await axios.delete(
      `${SERVERURL}/achievement/remove/${achievementId}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return response.status;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};
