import { Box, Fade, Grow, Slide, Typography, useTheme } from "@mui/material";
import { NavLink } from "../../../models/global/Types";
import { StyledNavlink } from "./Navlink.styled";
import { useNavigate } from "react-router-dom";

type NavlinkProps = {
  navlink: NavLink;
  isextended: boolean;
  index: number;
};

export const Navlink = ({ navlink, isextended, index }: NavlinkProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <StyledNavlink
      theme={theme}
      isextended={isextended}
      index={index}
      onClick={() => navigate(navlink.path)}
    >
      <Box>{navlink.icon}</Box>
      <Fade in={isextended} {...{ timeout: { enter: (index + 1) * 250 } }}>
        <Typography variant="h4">{navlink.title}</Typography>
      </Fade>
    </StyledNavlink>
  );
};
