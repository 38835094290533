import { create } from "zustand";
import { Profile } from "../models/Profile";
import { getProfile } from "../repositories/UserRepository";
import { LoginDto } from "../models/dto/LoginDTO";

export interface UserStore {
  user?: Profile;
  fetchProfile: (loginData: LoginDto) => Promise<number | undefined>;
}

export const useProfile = create<UserStore>((set) => ({
  fetchProfile: (loginData: LoginDto) => {
    return getProfile(set, loginData);
  },
}));
