import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../../models/global/Types";
import { StyledCMS } from "../Cms.styled";

export const StyledServices = styled(StyledCMS)(
  ({ theme }: StyledComponentProps) => ({
    h1: {
      color: theme.color.dark,
    },
    ".serviceWrapper": {
      paddingBlock: "2rem",
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      width: "60%",
    },
  })
);
