import { ReactNode, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Service } from "../../../../models/Service";
import { useService } from "../../../../stores/useServices";
import { StyledServicePage } from "./Service.styled";
import { Box, Button, Typography } from "@mui/material";
import { PencilSimple } from "@phosphor-icons/react";
import { DarkOverlay } from "../../../../components/darkOverlay/DarkOverlay";
import { ServiceContentForm } from "../../../../components/form/ServiceContentForm";
import { ServiceContentComponent } from "../../../../components/service/serviceContent/ServiceContent";
import { useTextFormatter } from "../../../../hooks/useTextFormatter";
import { BE, GB } from "country-flag-icons/react/3x2";
import { LanguageContext } from "../../../../globals/LanguageContext";
import { ServicesForm } from "../../../../components/form/ServicesForm";
import { ServiceContent } from "../../../../models/ServiceContent";

export const ServicePage = () => {
  const { id } = useParams();
  const [service, setService] = useState<Service>();
  const [darkOverlayOpened, setDarkOverlayOpened] = useState<boolean>(false);
  const [darkOverlayComponent, setDarkOverlayComponent] = useState<ReactNode>(
    <></>
  );
  const { fetchService } = useService();
  const { renderText } = useTextFormatter();
  const { dictionary } = useContext(LanguageContext);
  const navigate = useNavigate();

  useEffect(() => {
    updateItems();
  }, [id]);

  const updateItems = () => {
    if (!id) return;
    fetchService(Number(id)).then((res) => {
      setService(res);
    });
    setDarkOverlayOpened(false);
  };

  const onServiceEdit = () => {
    setDarkOverlayComponent(
      <ServicesForm onSave={updateItems} service={service} />
    );
    setDarkOverlayOpened(true);
  };

  if (!service) return <></>;

  const onNewServiceContent = () => {
    setDarkOverlayComponent(
      <ServiceContentForm serviceId={service.id} onSave={updateItems} />
    );
    setDarkOverlayOpened(true);
  };

  const onServiceContentEdit = (serviceContent: ServiceContent) => {
    setDarkOverlayComponent(
      <ServiceContentForm
        onSave={updateItems}
        serviceId={service.id}
        serviceContent={serviceContent}
      />
    );
    setDarkOverlayOpened(true);
  };

  return (
    <StyledServicePage>
      <DarkOverlay
        opened={darkOverlayOpened}
        component={darkOverlayComponent}
        onClose={() => setDarkOverlayOpened(false)}
      />
      <Box className="header">
        <Button onClick={() => navigate("/cms/services")}>
          {dictionary.buttons.gobackbtn}
        </Button>
        <Typography variant="h1">
          {service?.title.translationDutch} /{" "}
          {service?.title.translationEnglish}
        </Typography>
        <PencilSimple size={48} onClick={onServiceEdit} />
      </Box>
      <Box className="body">
        <Box className="imageWrapper">
          <img src={`data:image/png;base64,${service?.image}`} />
        </Box>
        <Box className="descriptionWrapper">
          <Box className="description">
            <Box className="flag">
              <BE title="Flag Belgium" />
            </Box>
            <Typography variant="body1">
              {renderText(service?.description.translationDutch)}
            </Typography>
          </Box>
          <Box className="description">
            <Box className="flag">
              <GB title="Flag England" />
            </Box>
            <Typography variant="body1">
              {renderText(service?.description.translationEnglish)}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Typography variant="h2">
        {dictionary.pages.cms.tabs.services.content}
      </Typography>
      <Box className="contentWrapper">
        <Button onClick={onNewServiceContent}>
          {dictionary.buttons.newservicecontentbtn}
        </Button>
        {service &&
          service.content.map((serviceContent) => (
            <ServiceContentComponent
              serviceContent={serviceContent}
              updateItems={updateItems}
              onEdit={() => onServiceContentEdit(serviceContent)}
            />
          ))}
      </Box>
    </StyledServicePage>
  );
};
