import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../../models/global/Types";

export const StyledChatPage = styled(Box)(
  ({ theme }: StyledComponentProps) => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "scroll",
    alignItems: "center",
    gap: "2rem",
    maxHeight: "100vh",
    ".header": {
      width: "100%",
      position: "relative",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingInline: "2rem",
      boxSizing: "border-box",
      button: {
        width: "10rem",
      },
      h2: {
        paddingBlock: "1rem",
        maxWidth: "80%",
      },
      svg: {
        width: "10rem",
        color: theme.palette.error.main,
        cursor: "pointer",
        "&:hover": {
          color: theme.color.dark,
          transition: ".5s",
        },
      },
    },
    ".contactInformationWrapper": {
      display: "flex",
      flexWrap: "wrap",
      width: "80%",
      gap: "1rem",
      justifyContent: "space-evenly",
      ".contactinformation": {
        width: "30rem",
        display: "flex",
        gap: "1rem",
        alignItems: "center",
        svg: {
          color: theme.color.main,
        },
      },
    },
    ".messageWrapper": {
      width: "80%",
      border: `2px solid ${theme.color.dark}`,
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      padding: "1rem",
      borderRadius: "25px",
      ".wrapper": {},
    },
  })
);
