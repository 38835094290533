import { Box, Button, Typography } from "@mui/material";
import { StyledAchievements } from "./Achievement.styled";
import { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../../globals/LanguageContext";
import { CmsNavbar } from "../../../components/cmsNavbar/CmsNavbar";
import { DarkOverlay } from "../../../components/darkOverlay/DarkOverlay";
import { AchievementForm } from "../../../components/form/AchievementForm";
import { useAchievement } from "../../../stores/useAchievement";
import { Achievement } from "../../../models/Achievement";
import { AchievementComponent } from "../../../components/achievementComponent/AchievementComponent";

export const Achievements = () => {
  const { dictionary } = useContext(LanguageContext);
  const [darkOverlayOpened, setDarkOverlayOpened] = useState<boolean>(false);
  const [achievements, setAchievements] = useState<Achievement[]>([]);

  const { fetchAchievements } = useAchievement();

  useEffect(() => {
    updateItems();
  }, []);

  const updateItems = () => {
    fetchAchievements().then((res) => {
      if (!res) return;
      setAchievements(res);
    });
  };

  const onNewAchievement = () => {
    setDarkOverlayOpened(false);
    updateItems();
  };

  return (
    <StyledAchievements>
      <CmsNavbar />
      <DarkOverlay
        opened={darkOverlayOpened}
        component={<AchievementForm onSave={onNewAchievement} />}
        onClose={() => {
          setDarkOverlayOpened(false);
        }}
      />
      <Typography variant="h1">
        {dictionary.pages.cms.tabs.achievements.title}
      </Typography>
      <Button onClick={() => setDarkOverlayOpened(true)}>
        {dictionary.buttons.newachievement}
      </Button>
      <Box className="achievementWrapper">
        {achievements.map((achievement) => (
          <AchievementComponent
            achievement={achievement}
            onUpdate={updateItems}
          />
        ))}
      </Box>
    </StyledAchievements>
  );
};
