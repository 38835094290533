import { useContext } from "react";
import { NavLink } from "../models/global/Types";
import { LanguageContext } from "../globals/LanguageContext";

export const useCmsNavbar = () => {
  const { dictionary } = useContext(LanguageContext);

  const links: NavLink[] = [
    {
      title: dictionary.pages.cms.tabs.achievements.title,
      path: "/cms/achievements",
    },
    {
      title: dictionary.pages.cms.tabs.services.title,
      path: "/cms/services",
    },
    {
      title: dictionary.pages.cms.tabs.language.title,
      path: "/cms/language",
    },
    {
      title: dictionary.pages.cms.tabs.images.title,
      path: "/cms/images",
    },
  ];

  return { links };
};
