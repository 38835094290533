import { Button, Slide } from "@mui/material";
import { StyledConfirmation } from "./Confirmation.styled";
import { useContext } from "react";
import { LanguageContext } from "../../globals/LanguageContext";

type ConfirmationProps = {
  onConfirm: () => void;
  onDecline: () => void;
  confirmationOpen: boolean;
};

export const Confirmation = ({
  onConfirm,
  onDecline,
  confirmationOpen,
}: ConfirmationProps) => {
  const { dictionary } = useContext(LanguageContext);
  return (
    <Slide in={confirmationOpen} direction="left" mountOnEnter unmountOnExit>
      <StyledConfirmation>
        <Button className="confirmBtn" onClick={onConfirm}>
          {dictionary.buttons.confirmbtn}
        </Button>
        <Button className="declineBtn" onClick={onDecline}>
          {dictionary.buttons.declinebtn}
        </Button>
      </StyledConfirmation>
    </Slide>
  );
};
