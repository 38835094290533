import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../../models/global/Types";

interface StyledLanguageGroupProps extends StyledComponentProps {
  isopened: boolean;
}

export const StyledLanguageGroup = styled(Box)(
  ({ theme, isopened }: StyledLanguageGroupProps) => ({
    borderRadius: "25px",
    paddingInline: "5rem",
    border: `3px solid ${isopened ? theme.color.dark : theme.color.main}`,
    width: "100%",
    boxSizing: "border-box",
    ".header": {
      display: "flex",
      alignItems: "center",
      height: "5rem",
      gap: "2rem",
      cursor: "pointer",
      svg: {
        color: isopened ? theme.color.dark : theme.color.main,
        width: "10%",
      },
      h2: {
        color: isopened ? theme.color.dark : theme.color.main,
        width: "80%",
        textTransform: "capitalize",
      },
    },
    ".body": {
      paddingBlock: "1rem",
      display: "flex",
      flexDirection: "column",
      gap: ".25rem",
      strong: { color: theme.color.main },
    },
    "&:hover": {
      borderColor: theme.color.dark,
      ".header h2, .header svg": {
        color: theme.color.dark,
        transition: ".5s",
      },
      transition: ".5s",
    },
  })
);
