import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/global/Types";

export const StyledCMS = styled(Box)(({ theme }: StyledComponentProps) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  maxHeight: "100vh",
  overflowY: "scroll",
  h1: {
    color: theme.color.dark,
    paddingBlock: "1rem",
  },
}));
