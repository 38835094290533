import { Box, Typography } from "@mui/material";
import { StyledServiceContent } from "./ServiceContent.styled";
import { ServiceContent } from "../../../models/ServiceContent";
import { PencilSimple, TrashSimple } from "@phosphor-icons/react";
import { useService } from "../../../stores/useServices";
import { useTextFormatter } from "../../../hooks/useTextFormatter";
import { useProfile } from "../../../stores/useProfile";

type ServiceContentProps = {
  serviceContent: ServiceContent;
  updateItems: () => void;
  onEdit: () => void;
};

export const ServiceContentComponent = ({
  serviceContent,
  updateItems,
  onEdit,
}: ServiceContentProps) => {
  const { removeServiceContent } = useService();
  const { renderText } = useTextFormatter();

  const { user } = useProfile();

  const onRemove = () => {
    if (!user) return;
    removeServiceContent(user, serviceContent.id);
    updateItems();
  };

  return (
    <StyledServiceContent>
      <Box className="buttonWrapper">
        <PencilSimple
          size={38}
          className="editBtn"
          weight="bold"
          onClick={onEdit}
        />
        <TrashSimple
          size={38}
          className="removeBtn"
          weight="bold"
          onClick={onRemove}
        />
      </Box>
      <Box className="imageWrapper">
        <img src={`data:image/png;base64,${serviceContent?.image}`} />
      </Box>
      <Box className="body">
        <Typography>
          {renderText(serviceContent.title.translationDutch)}
        </Typography>
        <Typography variant="body1" className="text">
          {renderText(serviceContent.body.translationDutch)}
        </Typography>
      </Box>
      <Box className="body">
        <Typography>
          {renderText(serviceContent.title.translationEnglish)}
        </Typography>
        <Typography variant="body1" className="text">
          {renderText(serviceContent.body.translationEnglish)}
        </Typography>
      </Box>
    </StyledServiceContent>
  );
};
