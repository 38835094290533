import { Box, Button, Fade, useTheme } from "@mui/material";
import { StyledNavbar } from "./Navbar.styled";
import image_logowhite from "../../images/Logo/selico.logo.white.notext.svg";
import image_icon from "../../images/Logo/selico.icon.svg";
import { actionButtons } from "../../globals/constants";
import { Navlink } from "./navlink/Navlink";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useNavbar } from "../../hooks/useNavbar";

export const Navbar = () => {
  const [isExtended, setIsExtended] = useState<boolean>(true);
  const theme = useTheme();
  const navigate = useNavigate();
  const { links } = useNavbar();

  return (
    <StyledNavbar theme={theme} isextended={isExtended}>
      <Box className="header">
        <img src={isExtended ? image_logowhite : image_icon} />
      </Box>
      <Box className="navlinks">
        {links.map((navlink, index) => (
          <Navlink
            key={index}
            navlink={navlink}
            isextended={isExtended}
            index={index}
          />
        ))}
      </Box>
      <Box className="buttonWrapper">
        {actionButtons.map((btn, index) => (
          <>
            {index === 0 ? (
              <Button
                className="actionBtn"
                onClick={() => setIsExtended(!isExtended)}
              >
                {isExtended ? btn.icon : btn.altIcon}
              </Button>
            ) : (
              <Fade
                in={isExtended}
                {...{ timeout: { enter: 1000 + index * 500 } }}
              >
                <Button
                  className="actionBtn"
                  onClick={() => navigate(btn.path)}
                >
                  {btn.icon}
                </Button>
              </Fade>
            )}
          </>
        ))}
      </Box>
    </StyledNavbar>
  );
};
