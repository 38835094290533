import { useContext } from "react";
import { NavLink } from "../models/global/Types";
import { LanguageContext } from "../globals/LanguageContext";
import {
  Bank,
  Briefcase,
  CalendarBlank,
  Car,
  ChartBar,
  ChartDonut,
  ChatsCircle,
  Folder,
  Gear,
  Invoice,
  Power,
  Stack,
  UsersThree,
} from "@phosphor-icons/react";

export const useNavbar = () => {
  const { dictionary } = useContext(LanguageContext);

  const iconSizeNavbar = 28;

  const links: NavLink[] = [
    {
      title: dictionary.pages.home.title,
      path: "/",
      icon: <ChartBar size={iconSizeNavbar} />,
    },
    {
      title: dictionary.pages.calandar.title,
      path: "/agenda",
      icon: <CalendarBlank size={iconSizeNavbar} />,
    },
    {
      title: dictionary.pages.projects.title,
      path: "/projects",
      icon: <Briefcase size={iconSizeNavbar} />,
    },
    {
      title: dictionary.pages.inventory.title,
      path: "/inventory",
      icon: <Stack size={iconSizeNavbar} />,
    },
    {
      title: dictionary.pages.invoices.title,
      path: "/invoices",
      icon: <Invoice size={iconSizeNavbar} />,
    },
    {
      title: dictionary.pages.files.title,
      path: "/files",
      icon: <Folder size={iconSizeNavbar} />,
    },
    {
      title: dictionary.pages.employees.title,
      path: "/employees",
      icon: <UsersThree size={iconSizeNavbar} />,
    },
    {
      title: dictionary.pages.statistics.title,
      path: "/statistics",
      icon: <ChartDonut size={iconSizeNavbar} />,
    },
    {
      title: dictionary.pages.finances.title,
      path: "/finances",
      icon: <Bank size={iconSizeNavbar} />,
    },
    {
      title: dictionary.pages.vehicles.title,
      path: "/vehicles",
      icon: <Car size={iconSizeNavbar} />,
    },
    {
      title: dictionary.pages.chats.title,
      path: "/chats",
      icon: <ChatsCircle size={iconSizeNavbar} />,
    },
    {
      title: dictionary.pages.cms.title,
      path: "/cms/achievements",
      icon: <Gear size={iconSizeNavbar} />,
    },
  ];

  return { links };
};
