import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../../models/global/Types";
import { StyledCMS } from "../Cms.styled";

export const StyledLanguages = styled(StyledCMS)(
  ({ theme }: StyledComponentProps) => ({
    ".languageWrapper": {
      width: "80%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "1rem",
      paddingBlock: "2rem",
    },
  })
);
