import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/global/Types";

export const StyledCmsNavbar = styled(Box)(
  ({ theme }: StyledComponentProps) => ({
    width: "100%",
    minHeight: "5rem",
    boxShadow: `0px 0px 6px 0px ${theme.contrast.shadowColor}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    svg: {
      position: "absolute",
      left: "2rem",
    },
    button: {
      background: "none",
      color: theme.color.dark,
      width: "15rem",
      fontSize: "1.2rem",
      textTransform: "capitalize",
      "&:hover": {
        background: "none",
        color: theme.color.main,
      },
    },
    ".selected": {
      color: theme.color.main,
    },
  })
);
