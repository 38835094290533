import { useNavigate, useParams } from "react-router-dom";
import { StyledChatPage } from "./ChatPage.styled";
import { Chat } from "../../../models/Chat";
import { useContext, useEffect, useState } from "react";
import { useChat } from "../../../stores/useChat";
import { useTextFormatter } from "../../../hooks/useTextFormatter";
import { LanguageContext } from "../../../globals/LanguageContext";
import { Box, Button, Typography } from "@mui/material";
import {
  EnvelopeSimple,
  HouseSimple,
  PhoneCall,
  TrashSimple,
  User,
} from "@phosphor-icons/react";
import { MessageComponent } from "../../../components/message/Message";
import { MessageForm } from "../../../components/chatComponent/messageForm/MessageForm";
import { Confirmation } from "../../../components/confirmation/Confirmation";
import { useProfile } from "../../../stores/useProfile";

export const ChatPage = () => {
  const { id } = useParams();
  const [chat, setChat] = useState<Chat>();
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
  const { fetchChat, removeChat } = useChat();
  const { user } = useProfile();
  const { dictionary } = useContext(LanguageContext);
  const navigate = useNavigate();

  useEffect(() => {
    updateItems();
  }, [id]);

  const updateItems = () => {
    if (!id || !user) return;
    fetchChat(user, Number(id)).then((res) => {
      setChat(res);
    });
  };

  const onConfirm = () => {
    if (!user) return;
    removeChat(user, Number(id)).then((res) => {});
    updateItems();
    navigate("/chats");
  };

  const onSave = () => {
    updateItems();
  };

  if (!id) return <></>;

  return (
    <StyledChatPage>
      <Box className="header">
        <Button onClick={() => navigate("/chats")}>
          {dictionary.buttons.gobackbtn}
        </Button>
        <Typography variant="h2">
          {chat?.subject}
          <Typography variant="h4">REF: #{chat?.id}</Typography>
        </Typography>
        <TrashSimple
          size={48}
          weight="bold"
          onClick={() => setConfirmationOpen(true)}
        />
        <Confirmation
          confirmationOpen={confirmationOpen}
          onConfirm={onConfirm}
          onDecline={() => {
            setConfirmationOpen(false);
          }}
        />
      </Box>
      <Box className="contactInformationWrapper">
        <Box className="contactinformation">
          <User size={32} weight="bold" />
          <Typography variant="h3">
            {chat?.author.lastName} {chat?.author.firstName}
          </Typography>
        </Box>
        <Box className="contactinformation">
          <EnvelopeSimple size={32} weight="bold" />
          <Typography variant="h3">{chat?.author.email}</Typography>
        </Box>
        <Box className="contactinformation">
          <PhoneCall size={32} weight="bold" />
          <Typography variant="h3">{chat?.author.phone}</Typography>
        </Box>
        <Box className="contactinformation">
          <HouseSimple size={32} weight="bold" />
          <Typography variant="h3">{chat?.author.address}</Typography>
        </Box>
      </Box>
      <Box className="messageWrapper">
        {chat &&
          chat.messages
            .sort(
              (a, b) =>
                new Date(a.timeStamp).getTime() -
                new Date(b.timeStamp).getTime()
            )
            .map((message) => <MessageComponent message={message} />)}
        <Box className="wrapper">
          <MessageForm chatId={id} onSave={onSave} />
        </Box>
      </Box>
    </StyledChatPage>
  );
};
