import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../../../models/global/Types";

export const StyledServicePage = styled(Box)(
  ({ theme }: StyledComponentProps) => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "scroll",
    alignItems: "center",
    gap: "2rem",
    maxHeight: "100vh",
    ".header": {
      width: "100%",
      position: "relative",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingInline: "2rem",
      boxSizing: "border-box",
      button: {
        width: "10rem",
      },
      h1: {
        paddingBlock: "1rem",
        maxWidth: "80%",
      },
      svg: {
        width: "10rem",
        color: theme.palette.warning.main,
        cursor: "pointer",
        "&:hover": {
          color: theme.color.dark,
          transition: ".5s",
        },
      },
    },
    ".body": {
      display: "flex",
      justifyContent: "space-between",
      width: "80%",
      ".imageWrapper": {
        width: "25rem",
        height: "25rem",
        border: `3px solid ${theme.color.main}`,
        borderRadius: "25px",
        overflow: "hidden",
        img: {
          width: "100%",
          height: "100%",
          objectFit: "cover",
        },
      },
      ".descriptionWrapper": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        maxWidth: "60%",
        ".description": {
          width: "100%",
          overflowY: "scroll",
          maxHeight: "12rem",
          height: "12rem",
          position: "relative",
          boxSizing: "border-box",
          padding: "0.5rem 1rem",
          display: "flex",
          gap: "1rem",
          ".flag": {
            minWidth: "3rem",
            height: "2rem",
            borderRadius: "5px",
            overflow: "hidden",
          },
          p: {
            height: "100%",
          },
        },
      },
    },
    ".contentWrapper": {
      width: "80%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      gap: "1rem",
    },
  })
);
