import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/global/Types";

export const StyledService = styled(Box)(({ theme }: StyledComponentProps) => ({
  height: "5rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: "25px",
  border: `3px solid ${theme.color.main}`,
  paddingInline: "2rem",
  position: "relative",
  overflow: "hidden",
  h4: {
    color: theme.color.dark,
    fontWeight: "bold",
    width: "70%",
    fontSize: "1.25rem",
  },
  ".buttonWrapper": {
    display: "flex",
    gap: "1rem",
    svg: {
      cursor: "pointer",
      "&:hover": {
        color: theme.color.dark,
        transition: ".5s",
      },
    },
    ".editBtn": {
      color: theme.palette.warning.main,
    },
    ".removeBtn": {
      color: theme.palette.error.main,
    },
  },
}));
