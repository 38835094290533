import { create } from "zustand";
import { Language } from "../models/Language";
import {
  createLanguage,
  getLanguages,
  updateLanguage,
} from "../repositories/LanguageRepository";
import { LanguageFormDTO } from "../models/dto/LanguageFormDTO";
import { Profile } from "../models/Profile";

export interface LanguageStore {
  languageList?: Language[];
  addLanguage: (
    user: Profile,
    language: LanguageFormDTO
  ) => Promise<number | undefined>;
  editLanguage: (
    user: Profile,
    languageId: number,
    language: LanguageFormDTO
  ) => Promise<number | undefined>;
  fetchLanguages: (user: Profile) => Promise<Language[] | undefined>;
}

export const useLanguage = create<LanguageStore>((set) => ({
  addLanguage: (user: Profile, language: LanguageFormDTO) => {
    return createLanguage(user, language);
  },
  editLanguage: (
    user: Profile,
    languageId: number,
    language: LanguageFormDTO
  ) => {
    return updateLanguage(user, languageId, language);
  },
  fetchLanguages: (user: Profile) => {
    return getLanguages(user, set);
  },
}));
