import { Controller, useForm } from "react-hook-form";
import { StyledLanguageForm } from "./LanguageForm.styled";
import { LanguageFormDTO } from "../../../models/dto/LanguageFormDTO";
import { useLanguage } from "../../../stores/useLanguage";
import { useContext, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { LanguageContext } from "../../../globals/LanguageContext";
import { TextArea } from "../../textArea/TextArea";
import { Language } from "../../../models/Language";
import { useProfile } from "../../../stores/useProfile";

type LanguageFormProps = {
  onSave: () => void;
  language?: Language;
};

export const LanguageForm = ({ onSave, language }: LanguageFormProps) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LanguageFormDTO>({});
  const { dictionary } = useContext(LanguageContext);
  const [translationDutch, setTranslationDutch] = useState<string>("");
  const [translationEnglish, setTranslationEnglish] = useState<string>("");

  const { addLanguage, editLanguage } = useLanguage();

  const { user } = useProfile();

  const onSubmit = async (data: LanguageFormDTO) => {
    const newData = {
      ...data,
      translationDutch: translationDutch,
      translationEnglish: translationEnglish,
    };
    if (!user) return;
    const res = language
      ? await editLanguage(user, language.id, newData)
      : await addLanguage(user, newData);
    if (res) onSave();
  };

  return (
    <StyledLanguageForm onSubmit={handleSubmit(onSubmit)}>
      <Box className="row">
        <Box className="column">
          <Typography variant="h3">
            {dictionary.pages.cms.tabs.language.form.name}
          </Typography>
          <Controller
            name="name"
            rules={{ required: dictionary.errormessages.form_fieldrequired }}
            control={control}
            defaultValue={language?.name}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Box>
        <Box className="column">
          <Typography variant="h3">
            {dictionary.pages.cms.tabs.language.form.group}
          </Typography>
          <Controller
            name="group"
            rules={{ required: dictionary.errormessages.form_fieldrequired }}
            control={control}
            defaultValue={language?.group}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Box>
      </Box>
      <Box className="row">
        <Box className="column">
          <Typography variant="h3">
            {dictionary.pages.cms.tabs.language.form.translationdutch}
          </Typography>
          <TextArea
            setValue1={setTranslationDutch}
            {...register("translationDutch", {
              required: false,
            })}
            InputLabelProps={{ shrink: true }}
            multiline
            InputProps={{
              rows: 10,
            }}
            value={language?.translationDutch}
          />
        </Box>
      </Box>
      <Box className="row">
        <Box className="column">
          <Typography variant="h3">
            {dictionary.pages.cms.tabs.language.form.translationenglish}
          </Typography>
          <TextArea
            setValue2={setTranslationEnglish}
            {...register("translationEnglish", {
              required: false,
            })}
            InputLabelProps={{ shrink: true }}
            multiline
            InputProps={{
              rows: 10,
            }}
            value={language?.translationEnglish}
          />
        </Box>
      </Box>

      <Button type="submit">{dictionary.buttons.savebtn}</Button>
    </StyledLanguageForm>
  );
};
