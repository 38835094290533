import { ColorTheme } from "../models/global/ColorTheme";

export const defaultColors = {
  red: "#d92f25",
  orange: "#BF9000",
  green: "#548235",
};

export const themeColors: ColorTheme = {
  dark: "#1C1C1C",
  main: "#399D9B",
  lighter: "#67D3CC",
  light: "#ABE2DF",
  textColor: "#1C1C1C",
  contrastColor: "#67D3CC",
  background: "#EEF0F2",
};

export const contrastColors = {
  dark: "#404040",
  main: "#AFABAB",
  lighter: "#e3e4e6",
  light: "#FFFFFF",
  shadowColor: "rgba(115,115,115,0.25)",
};
