import {
  Package,
  Pencil,
  PencilSimple,
  TrashSimple,
} from "@phosphor-icons/react";
import { Service } from "../../models/Service";
import { StyledService } from "./Service.styled";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Confirmation } from "../confirmation/Confirmation";
import { useState } from "react";
import { useService } from "../../stores/useServices";
import { useProfile } from "../../stores/useProfile";

type ServiceComponentProps = {
  service: Service;
  onChange: () => void;
};

export const ServiceComponent = ({
  service,
  onChange,
}: ServiceComponentProps) => {
  const navigate = useNavigate();
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
  const { removeService } = useService();

  const { user } = useProfile();

  const onConfirm = () => {
    if (!user) return;
    removeService(user, service.id);
    setConfirmationOpen(false);
    onChange();
  };

  return (
    <StyledService>
      <Package size={48} />
      <Typography variant="h4">
        {service?.title.translationDutch} | {service?.title.translationEnglish}
      </Typography>
      <Box className="buttonWrapper">
        <PencilSimple
          size={38}
          className="editBtn"
          weight="bold"
          onClick={() => navigate(`/cms/services/${service.id}`)}
        />
        <TrashSimple
          size={38}
          className="removeBtn"
          weight="bold"
          onClick={() => setConfirmationOpen(true)}
        />
      </Box>
      <Confirmation
        confirmationOpen={confirmationOpen}
        onConfirm={onConfirm}
        onDecline={() => {
          setConfirmationOpen(false);
        }}
      />
    </StyledService>
  );
};
