import {
  Select,
  MenuItem,
  Box,
  Input,
  Typography,
  Button,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useLanguage } from "../../stores/useLanguage";
import { useService } from "../../stores/useServices";
import { ServiceContentFormDTO } from "../../models/dto/ServiceContentFormDTO";
import { ServiceContent } from "../../models/ServiceContent";
import { StyledForm } from "./Form.styled";
import { LanguageContext } from "../../globals/LanguageContext";
import { Upload } from "@phosphor-icons/react";
import { useProfile } from "../../stores/useProfile";

type ServiceContentProps = {
  serviceId: number;
  serviceContent?: ServiceContent;
  onSave: () => void;
};

export const ServiceContentForm = ({
  serviceId,
  serviceContent,
  onSave,
}: ServiceContentProps) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ServiceContentFormDTO>({});
  const { dictionary } = useContext(LanguageContext);
  const { addServiceContent } = useService();
  const { languageList, fetchLanguages } = useLanguage();
  const [imagePreview, setImagePreview] = useState<string | undefined>(
    serviceContent?.image
  );

  const { user } = useProfile();

  useEffect(() => {
    if (!user) return;
    fetchLanguages(user);
  }, []);

  const onSubmit = (data: ServiceContentFormDTO) => {
    if (!user) return;
    addServiceContent(user, data, serviceId);
    onSave();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        setImagePreview(base64String.split(",")[1]);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview("");
    }
  };

  useEffect(() => {
    setImagePreview(serviceContent?.image);
  }, [serviceContent?.image]);

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Box className="row">
        <Box className="column">
          <Controller
            rules={{ required: true }}
            control={control}
            name="titleId"
            defaultValue={serviceContent?.title.id}
            render={({ field }) => (
              <Select {...field}>
                {languageList?.map((language) => (
                  <MenuItem value={language.id}>{language.name}</MenuItem>
                ))}
              </Select>
            )}
          />
        </Box>
        <Box className="column">
          <Controller
            rules={{ required: true }}
            control={control}
            name="bodyId"
            defaultValue={serviceContent?.body.id}
            render={({ field }) => (
              <Select {...field}>
                {languageList?.map((language) => (
                  <MenuItem value={language.id}>{language.name}</MenuItem>
                ))}
              </Select>
            )}
          />
        </Box>
      </Box>
      <Box className="row">
        <Box className="column">
          <Typography variant="h3">
            {dictionary.pages.cms.tabs.services.form.image}
          </Typography>
          <Box className="fileInput">
            <Input
              type="file"
              {...register("files")}
              inputProps={{ multiple: false }}
              onChange={handleFileChange}
            />
            <Typography className="input-label">
              {dictionary.pages.cms.tabs.services.form.chooseimage}
              <Upload size={32} />
            </Typography>
          </Box>
        </Box>
        <Box className="column">
          <Typography variant="h3">
            {dictionary.pages.cms.tabs.services.form.imageexample}
          </Typography>
          <Box className="imageWrapper">
            <img src={`data:image/png;base64,${imagePreview}`} />
          </Box>
        </Box>
      </Box>

      <Button type="submit">{dictionary.buttons.savebtn}</Button>
    </StyledForm>
  );
};
