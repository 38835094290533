import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/global/Types";

interface StyledChatComponentProps extends StyledComponentProps {
  received: boolean;
}

export const StyledChatComponent = styled(Box)(
  ({ theme, received }: StyledChatComponentProps) => ({
    width: "30rem",
    borderRadius: "15px",
    display: "flex",
    alignItems: "center",
    boxShadow: `0px 0px 3px 3px ${theme.contrast.shadowColor}`,
    padding: ".5rem 2rem",
    boxSizing: "border-box",
    gap: "2rem",
    position: "relative",
    cursor: "pointer",
    overflow: "hidden",
    ".status": {
      position: "absolute",
      width: "1rem",
      height: "100%",
      background: received
        ? theme.palette.success.main
        : theme.palette.warning.main,
      left: "0",
    },
    ".info": {
      display: "flex",
      flexDirection: "column",
      width: "80%",
      h4: {
        fontWeight: "bold",
        color: theme.color.main,
        paddingBlock: "0",
        height: "min-content",
        lineHeight: "32px",
      },
      h5: {
        textWrap: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
      p: {
        color: theme.contrast.main,
        fontWeight: "bold",
      },
    },
    "&:hover": {
      borderColor: theme.color.main,
      transition: ".5s",
      svg: {
        color: theme.color.main,
        transition: ".5s",
      },
    },
  })
);
