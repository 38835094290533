import axios, { AxiosResponse } from "axios";
import { ServiceFormDTO } from "../models/dto/ServiceFormDTO";
import { SERVERURL } from "../globals/constants";
import { Service } from "../models/Service";
import { ServiceContentFormDTO } from "../models/dto/ServiceContentFormDTO";
import { Profile } from "../models/Profile";

export const getAllServices = async () => {
  const { data } = await axios.get<Service[]>(`${SERVERURL}/service/getall`, {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });

  return data;
};

export const getService = async (id: number) => {
  const { data } = await axios.get<Service>(`${SERVERURL}/service/get/${id}`, {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });

  return data;
};

export const createService = async (
  user: Profile,
  service: ServiceFormDTO
): Promise<number> => {
  const formData = new FormData();
  if (service.files) {
    const fileList = Array.from(service.files);

    fileList.forEach((file) => {
      formData.append("files", file);
    });
  }

  formData.append("data", JSON.stringify(service));

  try {
    const response: AxiosResponse = await axios.post(
      `${SERVERURL}/service/create`,
      formData,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return response.status;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};

export const createServiceContent = async (
  user: Profile,
  serviceContent: ServiceContentFormDTO,
  serviceId: number
): Promise<number> => {
  const formData = new FormData();
  if (serviceContent.files) {
    const fileList = Array.from(serviceContent.files);

    fileList.forEach((file) => {
      formData.append("files", file);
    });
  }

  formData.append("data", JSON.stringify(serviceContent));

  try {
    const response: AxiosResponse = await axios.post(
      `${SERVERURL}/service/addServiceContent/${serviceId}`,
      formData,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return response.status;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};

export const deleteServiceContent = async (
  user: Profile,
  contentId: number
) => {
  try {
    const response: AxiosResponse = await axios.delete(
      `${SERVERURL}/service/removeServiceContent/${contentId}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return response.status;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};

export const deleteService = async (user: Profile, serviceId: number) => {
  try {
    const response: AxiosResponse = await axios.delete(
      `${SERVERURL}/service/removeService/${serviceId}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return response.status;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};

export const updateService = async (
  user: Profile,
  serviceId: number,
  service: ServiceFormDTO
): Promise<number> => {
  const formData = new FormData();
  if (service.files) {
    const fileList = Array.from(service.files);

    fileList.forEach((file) => {
      formData.append("files", file);
    });
  }
  formData.append("data", JSON.stringify(service));

  try {
    const response: AxiosResponse = await axios.put(
      `${SERVERURL}/service/update/${serviceId}`,
      formData,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    return response.status;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};
