import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../../models/global/Types";

interface StyledNavlinkProps extends StyledComponentProps {
  isextended: boolean;
  index: number;
}

export const StyledNavlink = styled(Box)(
  ({ theme, isextended, index }: StyledNavlinkProps) => ({
    width: isextended ? "80%" : "5rem",
    borderRadius: "0 50px 50px 0",
    background: theme.color.main,
    padding: ".15rem 1rem",
    boxSizing: "border-box",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    color: theme.color.background,
    height: "2.5rem",
    transition: isextended ? `${index * 0.15}s` : ".5s",
    h4: {
      padding: "0",
      fontWeight: "bold",
    },
    svg: { fontWeight: "bold", width: "3rem" },
    "&:hover": {
      background: theme.color.background,
      color: theme.color.main,
      transition: ".5s",
    },
  })
);
