import { Box, useTheme } from "@mui/material";
import { StyledImageGrid, StyledImageGridItem } from "./ImageGrid.styled";
import { useEffect, useState } from "react";
import { getImageGrid } from "../../globals/utils";

type ImageGridProps = {
  imageList: string[];
};

export const ImageGrid = ({ imageList }: ImageGridProps) => {
  const theme = useTheme();
  const [imageGrid, setImageGrid] = useState<string[]>([]);

  useEffect(() => {
    setImageGrid(getImageGrid(imageList.length > 6 ? 6 : imageList.length));
  }, [imageList]);

  return (
    <StyledImageGrid>
      <Box className="grid">
        {imageList.slice(0, 6).map((image, index, array) => (
          <StyledImageGridItem
            theme={theme}
            gridArea={imageGrid[index]}
            key={index}
          >
            <img src={`data:image/png;base64,${image}`} />
          </StyledImageGridItem>
        ))}
      </Box>
    </StyledImageGrid>
  );
};
