import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/global/Types";

export const StyledAchievementComponent = styled(Box)(
  ({ theme }: StyledComponentProps) => ({
    width: "20rem",
    height: "20rem",
    boxShadow: `0px 0px 3px 3px ${theme.contrast.shadowColor}`,
    position: "relative",
    borderRadius: "25px",
    overflow: "hidden",
    svg: {
      position: "absolute",
      color: theme.palette.error.main,
      right: "1rem",
      top: "1rem",
      cursor: "pointer",
    },
    ".header": {
      width: "100%",
      height: "12.5rem",
      img: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
    },
    ".body": {
      textAlign: "center",
      h3: {
        color: theme.color.main,
        fontWeight: "bold",
      },
    },
  })
);
