import { Gear } from "@phosphor-icons/react";
import { StyledCmsNavbar } from "./CmsNavbar.styled";
import { Box, Button, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useCmsNavbar } from "../../hooks/useCmsNavbar";

export const CmsNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { links } = useCmsNavbar();

  return (
    <StyledCmsNavbar>
      <Gear size={48} />
      {links.map((cmsnavlink) => (
        <Button
          key={cmsnavlink.title}
          onClick={() => navigate(cmsnavlink.path)}
          className={location.pathname === cmsnavlink.path ? "selected" : ""}
        >
          {cmsnavlink.title}
        </Button>
      ))}
    </StyledCmsNavbar>
  );
};
