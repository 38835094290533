import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/global/Types";

export const StyledDarkOverlay = styled(Box)(
  ({ theme }: StyledComponentProps) => ({
    position: "fixed",
    background: "rgba(0, 0,0, 0.7)",
    zIndex: "60",
    width: "100%",
    height: "100%",
    left: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ".componentWrapper": {
      background: theme.color.background,
      maxWidth: "80rem",
      maxHeight: "90%",
      borderRadius: "25px",
      padding: "3rem",
    },
    ".closeBtn": {
      position: "absolute",
      right: "10rem",
      top: "5rem",
      color: theme.color.background,
      cursor: "pointer",
      "&:hover": {
        color: theme.color.main,
        transition: ".5s",
      },
    },
  })
);
