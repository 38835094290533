import { styled } from "@mui/material";
import { StyledComponentProps } from "../../models/global/Types";

export const StyledLoginForm = styled("form")(
  ({ theme }: StyledComponentProps) => ({
    display: "flex",
    flexDirection: "column",
    width: "25rem",
    gap: ".5rem",
    h4: {
      padding: "0",
      fontWeight: "bold",
      color: theme.color.dark,
      b: {
        color: theme.color.main,
      },
    },
    p: {
      color: theme.palette.error.main,
      fontFamily: "'Product Sans', sans-serif",
      fontWeight: "bold",
      position: "absolute",
      padding: ".5rem",
      right: "0",
    },
    button: {
      width: "100%",
    },
  })
);
