import { Chats, ChatsCircle } from "@phosphor-icons/react";
import { StyledChatComponent } from "./ChatComponent.styled";
import { Box, Typography, useTheme } from "@mui/material";
import { Chat } from "../../models/Chat";
import { useNavigate } from "react-router-dom";
import { defaultEmail } from "../../globals/constants";
import useDateFormatter from "../../hooks/useDateFormatter";

type ChatComponentProps = {
  chat: Chat;
};

export const ChatComponent = ({ chat }: ChatComponentProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { formatDate } = useDateFormatter();

  return (
    <StyledChatComponent
      theme={theme}
      received={chat.messages[chat.messages.length - 1].sender === defaultEmail}
      onClick={() => navigate(`/chats/${chat.id}`)}
    >
      <ChatsCircle size={48} />
      <Box className="status" />
      <Box className="info">
        <Typography variant="h4">{chat.subject}</Typography>
        <Typography variant="h5">
          {chat.author.lastName} {chat.author.firstName}
        </Typography>
        <Typography variant="body1">
          {formatDate(chat.timeStamp.toString())}
        </Typography>
      </Box>
    </StyledChatComponent>
  );
};
